html, div#root {
  background-color: #2e294e;
  color: #fff;
}
div#root {
  background-image: url('../assets/background.png');
}

div.card {
  color: #fff;
  background-color: #403a62;
}
div.card .card-title {
  color: #f0a202;
}
#videocontainer {
  width: 100%;
  padding-top: 56.25%;
}

#videocontainer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  filter:brightness(40%);
}

#warpcollapse{
  width: 15%;
  align-items: center;
  border-radius: 4px;

}
#videotext {
  position: absolute;
  z-index: 9;
  top: 0;
  font-size: 4.5rem;
  width: auto;
  line-height: 4rem;
  text-align: center;
  font-weight: 800;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
@media (max-width: 820px) {
  #videotext {
    font-size: 2rem;
    line-height: 3rem;
  }
  #videocontainer {
    padding-bottom: 60vh;
  }
  #warpcollapse{
    width: 30%;
  }
}
#buttonsaibamais{
position: relative;
left: 30%;
}