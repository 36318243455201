h1{
    font-size: 1.125rem
}
p{
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
}
ul{
    padding-left: 3rem;
}
.cronograma {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-family: "Montserrat", sans-serif; 
    font-size: 1.4rem
}
.cronograma h2{
    color: #eea85d;
    font-weight: 800;
    font-family: "Bebas Neue", sans-serif;
}
  
  .modulo {
    width: 250px;
    border-radius: 10px;
    box-shadow: 0 10px 5rem rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
  }
#button{
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    background-color: unset;
    border: 3px solid rgb(3, 85, 125);
    font-weight: 600;
    border-radius: 8px;
    padding: 12px 35px;
}
#button:hover{
    background-color: rgb(3, 85, 125);
    transition: 0.5s;
}
#buttoncontainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
#imagecontainer{
    width: 100%;
    padding-bottom: 100vh;
}
#imagecontainer img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
#info{
    font-weight: 800;
    font-family: "Bebas Neue", sans-serif;
    color: #eea85d;
}
#pilar{
    line-height: 1.2;
}